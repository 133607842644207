import './App.css';
import {FormattedMessage, useIntl} from "react-intl";

function App() {
    const intl = useIntl();

    let data = [
        {
            "img": "img/field.svg",
            "title": intl.formatMessage({id: 'item1.title', defaultMessage: "Sports"}),
            "txt": intl.formatMessage({id: 'item1.desc'})
        },
        {
            "img": "img/winner.svg",
            "title": intl.formatMessage({id: 'item2.title', defaultMessage: "Statistics"}),
            "txt": intl.formatMessage({id: 'item2.desc'})
        },
        {
            "img": "img/construction.svg",
            "title": intl.formatMessage({id: 'item3.title', defaultMessage: "Site constructor"}),
            "txt": intl.formatMessage({id: 'item3.desc'})
        },
        {
            "img": "img/planet-earth.png",
            "title": intl.formatMessage({id: 'item4.title', defaultMessage: "Language"}),
            "txt": intl.formatMessage({id: 'item4.desc'})
        },
        {
            "img": "img/support.svg",
            "title": intl.formatMessage({id: 'item5.title', defaultMessage: "Language"}),
            "txt": intl.formatMessage({id: 'item5.desc'})
        },
    ]

    return (
        <div className="App">
            <header className="App-header">
                <div id="banner">
                    <div>
                        <img src="img/logo.png"/>
                    </div>
                    <h2><FormattedMessage id="rstat.title" defaultMessage="Statistical engine"/></h2>
                    <p><FormattedMessage id="rstat.desc" defaultMessage="Conducting mass sports competitions"/></p>
                    <a href="mailto:hippik80@gmail.com"><FormattedMessage id="btn.try"
                                                                                          defaultMessage="Try"/></a>
                </div>
            </header>
            <div id="wrapper">
                <p style={{textAlign: "justify"}}>
                    <div style={{textAlign: "left", marginBottom: 30}}><span className="title">R-STAT</span></div>
                    <FormattedMessage id="content.about"/>
                    <br/>
                    <br/>
                    <div>
                        <FormattedMessage id="download.desc" defaultMessage="You can download the presentation and read more"/>:
                        &nbsp;
                        <a href="/file/r_stat_eng.pdf"><FormattedMessage id="download.presentation" defaultMessage="download"/></a>
                    </div>
                    <hr />
                </p>
                <div id="container">
                    {
                        data.map((item, i) =>
                            <div className="item">
                                <img src={item.img}/>
                                <h3>{item.title}</h3>
                                {item.txt}
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default App;
