import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {IntlProvider} from "react-intl";
import messages_ru from "./translations/ru.json";
import messages_en from "./translations/en.json";

const root = ReactDOM.createRoot(document.getElementById('root'));
const language = navigator.language.split(/[-_]/)[0];

const messages = {
    'ru': messages_ru,
    'en': messages_en
};

root.render(
  <React.StrictMode>
      <IntlProvider locale={language} messages={messages[language]}>
            <App />
      </IntlProvider>,
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
